import { GeneralPath, MutablePoint, NodeStyleBase, SvgVisual } from "yfiles";

/**
 * A very simple implementation of an {@link INodeStyle}
 * that uses the convenience class {@link NodeStyleBase}
 * as the base class.
 */

let statusIconCount = 0;
let statusIconStartX = 90;
let statusIconWidth = 25;
let statusIconGap = 7;

export default class CompanyNodeStyle extends NodeStyleBase {
  constructor(currentEntityId) {
    super();
    this.$nodeColor = "rgba(0,130,180,1)";
    this.currentEntityId = currentEntityId;
  }

  /**
   * Creates the visual for a node.
   * @see Overrides {@link NodeStyleBase#createVisual}
   * @return {SvgVisual}
   */
  createVisual(context, node) {
    // This implementation creates a 'g' element and uses it as a container for the rendering of the node.
    const g = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );
    // Render the node
    this.render(context, node, g);
    // set the location
    SvgVisual.setTranslate(g, node.layout.x, node.layout.y);
    return new SvgVisual(g);
  }

  /**
   * Actually creates the visual appearance of a node given the values provided by
   * {@link MySimpleNodeStyle#createRenderDataCache}. This renders the node and the edges to the labels and adds the
   * elements to the <code>container</code>. All items are arranged as if the node was located at (0,0).
   * {@link MySimpleNodeStyle#createVisual} and {@link MySimpleNodeStyle#updateVisual} finally arrange the container
   * so that the drawing is translated into the final position.
   */
  render(context, node, container) {
    statusIconCount = 0;
    let textColor = "rgb(0,0,0)";
    let fontSize = 16;
    if (node.tag.isCompany === false) {
      textColor = "rgb(255,255,255)";
    }
    // Create Defs section in container
    const defs = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "defs"
    );
    container.appendChild(defs);

    // determine the color to use for the rendering
    let bgColor = "rgb(255,255,255)";
    let borderRadius = 3;

    if (node.tag.isCompany === false) {
      bgColor = "#727994";
      //fontSize = 24;
    }

    if(node.tag.id.toLowerCase() === this.currentEntityId.toLowerCase()){
      bgColor = "#d7d9e2";
    }

    // the size of node
    const nodeSize = node.layout.toSize();

    const rect = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );

    rect.width.baseVal.value = nodeSize.width;
    rect.height.baseVal.value = nodeSize.height;
    rect.rx.baseVal.value = borderRadius;
    rect.ry.baseVal.value = borderRadius;
    rect.setAttribute("fill", bgColor);
    if (node.tag.isCompany === true) {
      rect.setAttribute("stroke", "rgb(61,65,86)");
      rect.setAttribute("stroke-width", "1");
    }

    const name = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );

    name.textContent = this.elipsisConverter(node.tag.name);
    name.setAttribute("x", 90);
    name.setAttribute("y", 45);
    name.setAttribute("fill", textColor);
    name.setAttribute("font-size", fontSize);
    name.setAttribute("font-family", "BisnodeSans-Bold");
    
    let scoreContent = this.generateScore(node.tag);

    container.appendChild(rect);
    container.appendChild(name);
    container.appendChild(scoreContent);

    if (node.tag.isPartner !== null) {
      const partner = window.document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );

      partner.textContent = "Partner";
      partner.setAttribute("x", 90);
      partner.setAttribute("y", 25);
      partner.setAttribute("fill", "#d53868");
      partner.setAttribute("font-size", 12);
      partner.setAttribute("font-family", "BisnodeSans-Bold");

      container.appendChild(partner);
    }

    if (node.tag.hasPositiveEvent === true) {
      let event = this.generateStatusIcon("positive");
      container.appendChild(event);
    }
    if (node.tag.hasLegalCourtEvent === true) {
      let event = this.generateStatusIcon("legalCourt");
      container.appendChild(event);
    }
    if (node.tag.hasLegalOtherEvent === true) {
      let event = this.generateStatusIcon("legalOther");
      container.appendChild(event);
    }
    if (node.tag.isActive === false) {
      if (node.tag.hasLegalCourtEvent === true) {
        let event = this.generateStatusIcon("inactiveWithCourtEvent");
        container.appendChild(event);
      } else {
        let event = this.generateStatusIcon("inactive");
        container.appendChild(event);
      }
    }
    if (node.tag.isPausedEntrepreneur === true) {
      let event = this.generateStatusIcon("paused");
      container.appendChild(event);
    }
    if (node.tag.isSuspendedLeader === true) {
      let event = this.generateStatusIcon("suspended");
      container.appendChild(event);
    }
  }

  generateScore(data) {
    let color = this.scoreColorConverter(data.ratingScore);

    let scoreWrapper = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );

    scoreWrapper.setAttribute("width", 64);
    scoreWrapper.setAttribute("height", 64);
    scoreWrapper.setAttribute("transform", "translate(15,15)");

    let scoreBorder = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle"
    );

    scoreBorder.setAttribute("cx", 32);
    scoreBorder.setAttribute("cy", 32);
    scoreBorder.setAttribute("r", 30);
    scoreBorder.setAttribute("fill", "rgb(255,255,255)");
    scoreBorder.setAttribute("stroke", color);
    scoreBorder.setAttribute("stroke-width", "5");

    let score = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );

    score.setAttribute("font-family", "FontAwesome");
    score.setAttribute("font-size", 36);
    score.setAttribute("x", 32);
    score.setAttribute("y", 32);
    score.setAttribute("fill", color);
    score.setAttribute("text-anchor", "middle");
    score.setAttribute("dy", ".4em");

    if (data.ratingScore.rating !== null) {
      score.setAttribute("font-family", "BisnodeSans-Bold");
      score.setAttribute("font-size", 24);
      score.textContent = data.ratingScore.rating;
    } else if (data.ratingScore.traffic !== null) {
      if (data.ratingScore.traffic === 3) {
        score.textContent = "\uf00c";
      } else if (data.ratingScore.traffic === 2) {
        score.textContent = "\uf128";
      } else if (data.ratingScore.traffic === 1) {
        score.textContent = "\uf00d";
      }
    } else if (data.ratingScore.rating10 !== null) {
      score.textContent =
        data.ratingScore.rating10 * 10 -
        9 +
        " - " +
        data.ratingScore.rating10 * 10;
    } else {
      score.textContent = "\uf128";
      if(data.isCompany === false){
        score.textContent = "\uf007";
      }
    }

    scoreWrapper.appendChild(scoreBorder);
    scoreWrapper.appendChild(score);

    return scoreWrapper;
  }

  generateStatusIcon(statusType) {
    const eventStatusWrapper = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );

    eventStatusWrapper.setAttribute("width", 32);
    eventStatusWrapper.setAttribute("height", 32);

    const eventCircle = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle"
    );

    eventCircle.setAttribute("cx", 16);
    eventCircle.setAttribute("cy", 16);
    eventCircle.setAttribute("r", 14);

    const eventStatus = window.document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );

    eventStatus.setAttribute("fill", "white");
    eventStatus.setAttribute("font-size", 14);
    eventStatus.setAttribute("font-family", "FontAwesome");
    eventStatus.setAttribute("x", 16);
    eventStatus.setAttribute("y", 16);
    eventStatus.setAttribute("text-anchor", "middle");
    eventStatus.setAttribute("dy", ".4em");

    switch (statusType) {
      case "positive":
        eventStatus.textContent = "\uf067";
        eventCircle.setAttribute("fill", "#4CAF50");
        break;
      case "legalCourt":
        eventStatus.textContent = "\uf0e3";
        eventCircle.setAttribute("fill", "#F44336");
        break;
      case "legalOther":
        eventStatus.textContent = "\uf12a";
        eventCircle.setAttribute("fill", "#FF9E01");
        break;
      case "inactive":
        eventStatus.textContent = "\uf00d";
        eventCircle.setAttribute("fill", "#0D0D0D");
        break;
      case "inactiveWithCourtEvent":
        eventStatus.textContent = "\uf00d";
        eventCircle.setAttribute("fill", "#F44336");
        break;
      case "suspended":
        eventStatus.textContent = "\uf007";
        eventCircle.setAttribute("fill", "#F44336");
        break;
      case "paused":
        eventStatus.textContent = "\uf04c";
        eventCircle.setAttribute("fill", "#0D0D0D");
        break;
      default:
        break;
    }

    let x =
      statusIconCount === 0
        ? statusIconStartX
        : statusIconStartX +
          statusIconCount * (statusIconWidth + statusIconGap);

    eventStatusWrapper.setAttribute("transform", `translate(${x},50)`);
    statusIconCount++;

    eventStatusWrapper.appendChild(eventCircle);
    eventStatusWrapper.appendChild(eventStatus);
    return eventStatusWrapper;
  }

  scoreColorConverter(ratingScore) {
    if (ratingScore !== undefined && ratingScore !== null) {
      switch (ratingScore.color) {
        case 1:
          return "#F44336";
        case 2:
          return "#FF9E01";
        case 3:
          return "#4CAF50";
      }
    }

    return "#BDBDBD";
  }

  eventBackgroundConverter(value, parameter) {
    var color = "#f5f5f5";
    switch (parameter) {
      case "hasPositiveEvent": {
        if (value.hasPositiveEvent === true) {
          color = "#4CAF50";
        }
        break;
      }
      case "hasLegalCourtEvent": {
        if (value.hasLegalCourtEvent == true) {
          color = "#F44336";
        }
        break;
      }
      case "hasLegalOtherEvent": {
        if (value.hasLegalOtherEvent == true) {
          color = "#FF9E01";
        }
        break;
      }
      case "isInactiveOrIsInactiveDueToLegalEvent": {
        if (value.isActive == false) {
          color = "#0D0D0D";
        }
        if (value.hasLegalCourtEvent == true && value.isActive == false) {
          color = "#F44336";
        }
        break;
      }
      case "isPausedEntrepreneur": {
        if (value.isPausedEntrepreneur == true) {
          color = "#0D0D0D";
        }
        break;
      }
      case "isSuspendedLeader": {
        if (value.isSuspendedLeader == true) {
          color = "#F44336";
        }
        break;
      }
    }

    return color;
  }

  elipsisConverter(value) {
    if (typeof value === "string" && value.length > 26) {
      return value.substring(0, 24) + "...";
    }
    return value;
  }
}
