export default class LicenseProvider
{
    ProvideLicense()
    {
        return {
            company: "Dun & Bradstreet Hungary Kft.",
            contact: "László Meidl",
            date: "12/06/2021",
            developer: "László Meidl (meidll@dnb.com)",
            distribution: true,
            domains: ["bisnode.hu"],
            email: "meidll@dnb.com",
            fileSystemAllowed: false,
            licensefileversion: "1.1",
            localhost: false,
            oobAllowed: false,
            package: "complete",
            product: "yFiles for HTML",
            subscription: "12/19/2022",
            type: "singledeveloper",
            version: "2.4",
            key: "2eedf60d32a2ee988b949696b7250ab64a4521cd"
          };
    }
}