import LayoutHandler from "./layout-handler";
import $ from "jquery";
import _ from "underscore";
import Noty from "noty";
import FileSaveSupport from "./file-save-support";
import ClientSidePdfExport from "./pdf-export";

let layoutHandler = null;
let clientSidePdfExport = null;

import {
  ICommand//, GraphWrapperBase, InputModeBase, KeyboardInputMode, GraphEditorInputMode
} from "yfiles";
//import { detectWindowsVersion } from "./workaround";

class Toolbar {
  constructor(graphComponent, currentEntityId, dataService, user) {
    this.graphComponent = graphComponent;
    this.currentEntityId = currentEntityId;
    this.dataService = dataService;
    this.user = user;
    this.initialize();
  }

  initialize() {
    if (this.user.isPro === false && this.user.isTest !== true) {
      $('.btn--graph-pro-demo').show();
      $('.graph-pro-info').show();
      $('#navigation-table-panel').removeClass('toolbar-panel--navigation-table');
      $('#entity-table').hide();
  }
    layoutHandler = new LayoutHandler(this.graphComponent);
    clientSidePdfExport = new ClientSidePdfExport();

    this.bindCommand(
      "a[data-command='ZoomIn']",
      ICommand.INCREASE_ZOOM,
      this.graphComponent
    );
    this.bindCommand(
      "a[data-command='ZoomOut']",
      ICommand.DECREASE_ZOOM,
      this.graphComponent
    );
    this.bindCommand(
      "a[data-command='FitContent']",
      ICommand.FIT_GRAPH_BOUNDS,
      this.graphComponent
    );
    this.bindCommand(
      "a[data-command='ZoomOriginal']",
      ICommand.ZOOM,
      this.graphComponent,
      1.0
    );

    $(".toolbar-menu-item").on("click", e => {
      e.preventDefault();
      this.hideAllToolbarPanel();

      if ($(e.currentTarget).hasClass("active") === true) {
        $(e.currentTarget).removeClass("active");
      } else {
        $(".toolbar-menu-item.active").removeClass("active");
        $(e.currentTarget).addClass("active");
        var btnId = $(e.currentTarget).attr("id");

        switch (btnId) {
          case "btnNavigator":
            $("#navigation-panel").show();
            break;
          case "btnFilter":
            $("#filter-panel").show();
            break;
          case "btnGraphType":
            $("#graph-type-panel").show();
            break;
          case "btnNavigationTable":
            $("#navigation-table-panel").show();
            break;
          case "btnExport":
            $("#export-panel").show();
            break;
          case "btnInfo":
            $("#info-panel").show();
            break;
        }
      }
    });

    $("#btnZoomToCurrentItem").on("click", e => {
      e.preventDefault();
      var currentNode = this.graphComponent.graph.nodes.find(
        x => x.tag.id.toLowerCase() === this.currentEntityId.toLowerCase()
      );
      this.updateSelection(this.graphComponent, currentNode);
      this.zoomToCurrentItem();
    });

    $("#entity-table th.sortable").on("click", e => {
      var sortField = $(e.currentTarget).attr("data-sorting-field");
      var sortDirection = "asc";
      var currentSortIcon = $(e.currentTarget).find("i.fa");

      if (!$(currentSortIcon).hasClass("fa-sort")) {
        if ($(currentSortIcon).hasClass("fa-sort-desc")) {
          sortDirection = "desc";
          $(currentSortIcon)
            .removeClass("fa-sort-desc")
            .addClass("fa-sort-asc");
        } else {
          sortDirection = "asc";
          $(currentSortIcon)
            .removeClass("fa-sort-asc")
            .addClass("fa-sort-desc");
        }
      } else {
        $(currentSortIcon)
          .removeClass("fa-sort")
          .addClass("fa-sort-asc");
      }

      $("#entity-table th.sortable i.fa")
        .not(currentSortIcon)
        .each(function(element) {
          $(element)
            .removeClass("fa-sort-desc")
            .removeClass("fa-sort-asc")
            .addClass("fa-sort");
        });

      this.generateToolbarTable(sortField, sortDirection);
    });

    this.bindAction("a[data-command='pdf-export']", () => {
      const scale = 1.0;
      const margin = 5.0;

      // configure export, export the image and show a dialog to save the image
      clientSidePdfExport.scale = scale;
      clientSidePdfExport.margins = new yfiles.geometry.Insets(margin);
      clientSidePdfExport
        .exportPdf(this.graphComponent.graph, null)
        .then(pdfUrl => {
          var currentNode = this.graphComponent.graph.nodes.find(
            x => x.tag.id.toLowerCase() == this.currentEntityId.toLowerCase()
          );
          var currentItemName = currentNode.tag.name;
          FileSaveSupport.save(pdfUrl, currentItemName + ".pdf").catch(() => {
            alert(
              "Saving directly to the filesystem is not supported by this browser. Please use the server based export instead."
            );
          });
        });
    });

    let apiUrl = "https://companydata.partnercontrol.hu";
    if (document.referrer.split('/')[2] == 'new.partnercontrol.hu')
    {
      apiUrl = "https://test.partnercontrol.hu";
    }

    this.bindAction("a[data-command='excel-export']", () => {
      let stepCount = $('input[name="step-count"]:checked').val();
      let ownerFilter = $('input[name="owner-filter"]:checked').val();
      let activeFilter = $('input[name="active-filter"]:checked').val();

      window.open(
        `${apiUrl}/api/graph/download/${
          this.currentEntityId
        }/${this.user.userId}/${
          this.user.sessionId
        }/${stepCount}/${ownerFilter}/${activeFilter}`,
        "_blank"
      );
    });

    $("#filter-btn").on("click", e => {
      e.preventDefault();
      $("#filter-form").submit();
    });

    $("#reset-btn").on("click", e => {
      e.preventDefault();
      let layout = $('input[name="layout-algorithm"]:checked').val();

      Noty.closeAll();
      new Noty({
        text: "Gráf generálása folyamatban.",
        theme: "nest",
        type: "info"
      })
        .show()
        .promises.show.then(async () => {
          Noty.closeAll();
          await this.changeLayout(layout);
          this.updateViewPort();
          new Noty({
            text: "Gráf generálása sikeresen befejeződött.",
            theme: "nest",
            type: "success",
            timeout: 2000
          }).show();
        });
    });



    $("input[name=layout-algorithm]").change(e => {
      if ($(e.currentTarget).is(":checked")) {
        Noty.closeAll();
        new Noty({
          text: "Gráf generálása folyamatban.",
          theme: "nest",
          type: "info"
        })
          .show()
          .promises.show.then(async () => {
            Noty.closeAll();
            await this.changeLayout($(e.currentTarget).val());
            this.updateViewPort();
            new Noty({
              text: "Gráf generálása sikeresen befejeződött.",
              theme: "nest",
              type: "success",
              timeout: 2000
            }).show();
          });
      }
    });

    $('#btnNavigator').click();
    
  }

  hideAllToolbarPanel() {
    $(".toolbar .toolbar-panel").hide();
  }

  bindAction(selector, action) {
    const element = document.querySelector(selector);
    if (!element) {
      return;
    }
    element.addEventListener("click", e => {
      action(e);
    });
  }

  bindCommand(selector, command, target, parameter) {
    const element = document.querySelector(selector);
    if (arguments.length < 4) {
      parameter = null;
      if (arguments.length < 3) {
        target = null;
      }
    }
    if (!element) {
      return;
    }
    command.addCanExecuteChangedListener((sender, e) => {
      if (command.canExecute(parameter, target)) {
        element.removeAttribute("disabled");
      } else {
        element.setAttribute("disabled", "disabled");
      }
    });
    element.addEventListener("click", e => {
      if (command.canExecute(parameter, target)) {
        command.execute(parameter, target);
      }
    });
  }

  getToolbarTableItemRatingValue(item) {
    if (item.ratingScore !== null) {
      if (
        item.ratingScore.traffic === null &&
        item.ratingScore.rating !== null
      ) {
        return item.ratingScore.rating;
      } else if (item.ratingScore.rating10 !== null) {
        return (
          item.ratingScore.rating10 * 10 -
          9 +
          " - " +
          item.ratingScore.rating10 * 10
        );
      } else {
        switch (item.ratingScore.traffic) {
          case 1:
            return '<i class="fa fa-times"></i>';
            break;
          case 2:
            return '<i class="fa fa-question"></i>';
            break;
          case 3:
            return '<i class="fa fa-check"></i>';
            break;
        }
      }
    }

    return '<i class="fa fa-question"></i>';
  }

  getToolbarTableItemEventValues(item) {
    var htmlString = "";
    if (item.hasPositiveEvent == true) {
      htmlString =
        htmlString +
        '<div class="event-icon event-icon--green"><i class="fa fa-plus"></i></div>';
    }
    if (item.isActive == false) {
      if (item.hasLegalCourtEvent == true) {
        htmlString =
          htmlString +
          '<div class="event-icon event-icon--red"><i class="fa fa-times"></i></div>';
      } else {
        htmlString =
          htmlString +
          '<div class="event-icon event-icon--black"><i class="fa fa-times"></i></div>';
      }
    }
    if (item.hasLegalOtherEvent == true) {
      htmlString =
        htmlString +
        '<div class="event-icon event-icon--yellow"><i class="fa fa-exclamation"></i></div>';
    }
    if (item.hasLegalCourtEvent == true) {
      htmlString =
        htmlString +
        '<div class="event-icon event-icon--red"><i class="fa fa-gavel"></i></div>';
    }
    if (item.isSuspendedLeader == true) {
      htmlString =
        htmlString +
        '<div class="event-icon event-icon--red"><i class="fa fa-user"></i></div>';
    }
    if (item.isPausedEntrepreneur == true) {
      htmlString =
        htmlString +
        '<div class="event-icon event-icon--black"><i class="fa fa-pause"></i></div>';
    }

    return htmlString;
  }

  zoomToCurrentItem() {
    const currentItem = this.graphComponent.currentItem;

    if (yfiles.graph.INode.isInstance(currentItem)) {
      if (this.graphComponent.graph.contains(currentItem)) {
        yfiles.input.ICommand.ZOOM_TO_CURRENT_ITEM.execute(
          null,
          this.graphComponent
        );
      }
    }
  }

  updateSelection(graphComponent, node) {
    if (node === null) {
      // clear the whole selection
      graphComponent.selection.clear();
    } else if (!graphComponent.selection.selectedNodes.isSelected(node)) {
      // no - clear the remaining selection
      graphComponent.selection.clear();
      // and select the node
      graphComponent.selection.selectedNodes.setSelected(node, true);
      // also update the current item
      graphComponent.currentItem = node;
    }
  }

  generateToolbarTable(sortField, sortDirection) {
    if (this.user.isPro === false && this.user.isTest !== true) {
        return;
    }
    let sortedCollection = null;
    $("#entity-table tbody tr").remove();
    if (sortField === "rating" || sortField === "name") {
      if (sortField === "rating") {
        sortedCollection = _.sortBy(this.dataService.getNodeSource(), function(
          item
        ) {
          if (item.ratingScore === null) return -1;

          if (item.ratingScore.traffic !== null) {
            switch (item.ratingScore.traffic) {
              case 1:
                return 36;
              case 2:
                return 75;

              case 3:
                return 100;
            }
          }

          if (item.ratingScore.rating10 !== null) {
            return item.ratingScore.rating10 * 10;
          }

          return item.ratingScore.rating;
        });
      }

      if (sortField === "name") {
        sortedCollection = this.dataService
          .getNodeSource()
          .sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
      }
    } else {
      sortedCollection = _.sortBy(this.dataService.getNodeSource(), sortField);
    }

    if (sortDirection == "desc") {
      sortedCollection = sortedCollection.reverse();
    }

    sortedCollection.forEach(element => {
      let imageUrl = "assets/images/";
      imageUrl =
        imageUrl +
        (element.isCompany !== true ? "person-icon.png" : "company-icon.png");
      var markup =
        '<tr><td class="center"><img src="' +
        imageUrl +
        '" alt="" /></td><td>' +
        element.name +
        '</td><td class="center"><div class="rating-circle" style="background:' +
        this.ratingColorConverter(element) +
        ';"><span class="value' +
        (element.ratingScore && element.ratingScore.rating10 !== null
          ? " value--small"
          : "") +
        '">' +
        this.getToolbarTableItemRatingValue(element) +
        '</span></div></td><td><div class="events-container">' +
        this.getToolbarTableItemEventValues(element) +
        "</div></td></tr>";
      $("#entity-table > tbody").append(markup);
      var row = $("#entity-table > tbody > tr:last-child");
      $(row).click(() => {
        var currentNode = this.graphComponent.graph.nodes.find(
          x => x.tag.id == element.id
        );
        this.updateSelection(this.graphComponent, currentNode);
        this.zoomToCurrentItem();
      });
    });
  }

  ratingColorConverter(value) {
    if (value === undefined) return;

    if (value.ratingScore !== undefined && value.ratingScore !== null) {
      switch (value.ratingScore.color) {
        case 1:
          return "#F44336";
        case 2:
          return "#FF9E01";
        case 3:
          return "#4CAF50";
      }
    }

    return "#BDBDBD";
  }

  async changeLayout(layout) {
    if (layoutHandler.layouting || (this.user.isPro === false && layout !== 'organic' && this.user.isTest !== true)) {
      return;
    }
    await layoutHandler.changeLayout(layout);
  }

  setLayoutButtons(status) {
    let selector = "input[name=layout-algorithm]";
    if (this.user.isPro === false && this.user.isTest !== true) {
        selector = 'input[value="organic"]';
    }
    $(selector).attr("disabled", status);
  }

  updateViewPort() {
    this.graphComponent.fitGraphBounds();
  }
}

export default Toolbar;
