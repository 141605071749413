export default class User{
    constructor(userId, sessionId, isPro, isTest){
        this.userId = userId;
        this.sessionId = sessionId;
        this.isPro = isPro;
        this.isTest = isTest;
    }

    userId(){
        return this.userId;
    }

    sessionId(){
        return this.sessionId;
    }

    isPro(){
        return this.isPro;
    }

    isTest(){
        return this.isTest;
    }
}