import $ from "jquery";
import Noty from "noty";

export default class DataService {
  constructor(user, currentEntityId, emitter) {
    this.nodeSource = null;
    this.edgeSource = null;
    this.user = user;
    this.currentEntityId = currentEntityId;
    this.emitter = emitter;
  }


  async fetchGraphData() {
    let stepCount = $('input[name="step-count"]:checked').val();
    let ownerFilter = $('input[name="owner-filter"]:checked').val();
    let activeFilter = $('input[name="active-filter"]:checked').val();
    let apiUrl = "https://companydata.partnercontrol.hu";
    if (document.referrer.split('/')[2] == 'new.partnercontrol.hu')
    {
      apiUrl = "https://test.partnercontrol.hu";
    }

    return await fetch(
      `${apiUrl}/api/graph/${this.currentEntityId}/${this.user.userId}/${this.user.sessionId}/${stepCount}/${ownerFilter}/${activeFilter}`
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 400) {
            this.emitter.emit("event:big-graph", {});
            throw "Túl nagy gráf. Szűkítsen a keresési feltételeken.";
          } else {
            throw "Hiba történt a gráf generálása közben";
          }
        }
      })
      .then(responseJson => {
        this.nodeSource = responseJson.nodes;
        this.edgeSource = responseJson.edges;
        if (this.startUp === true) {
          this.startUp = false;
        }
      });

    // return await fetch(
    //   `https://companydata.partnercontrol.hu/api/graph/${
    //     this.currentEntityId
    //   }/${this.user.userId}/${
    //     this.user.sessionId
    //   }/${stepCount}/${ownerFilter}/${activeFilter}`
    // )
    //   .then(response => response.json())
    //   .then(data => {
    //     this.nodeSource = data.nodes;
    //     this.edgeSource = data.edges;
    //   });
  }

  getNodeSource() {
    return this.nodeSource;
  }

  getEdgeSource() {
    return this.edgeSource;
  }
}
