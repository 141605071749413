import $ from "jquery";

import {
  OrganicLayout,
  HierarchicLayout,
  CircularLayout,
  OrthogonalLayout,
  RadialLayout,
  InteractiveOrganicLayout,
  Animator,
  GraphViewerInputMode,
  GraphEditorInputMode,
  DefaultLabelStyle,
  IEdge,
  INode,
  GraphItemTypes,
  ICommand,
  LayoutGraphAdapter,
  GraphConnectivity,
  EventRecognizers,
  EventArgs,
  Key,
  ModifierKeys
} from "yfiles";

const ZOOM_MAX_VALUE = 2.0;

/**
 * The maximal time the layout will run.
 */
const MAX_TIME = 1000;

/**
 * The copy of the graph used for the layout.
 * @type {CopiedLayoutGraph}
 */
let copiedLayoutGraph;

/**
 * Holds the nodes that are moved during dragging.
 * @type {Array.<INode>}
 */
let movedNodes;

/**
 * The context that provides control over the layout calculation.
 * @type {InteractiveOrganicLayoutExecutionContext}
 */
let layoutContext;

let layout = null;

let graphViewerInputMode = null;

let animator = null;

class LayoutHandler {
  constructor(graphComponent) {
    this.graphComponent = graphComponent;
    this.layouting = false;
  }

  getOrganicLayout() {
    let newLayout = new OrganicLayout();
    newLayout.nodeEdgeOverlapAvoided = true;
    newLayout.preferredEdgeLength = 80;
    newLayout.considerNodeSizes = true;
    newLayout.minimumNodeDistance = 80;

    return newLayout;
  }

  getHierarchicLayout() {
    let newLayout = new HierarchicLayout();
    newLayout.orthogonalRouting = true;
    newLayout.nodePlacer.barycenterMode = true;
    newLayout.fromScratchLayeringStrategy =
      yfiles.hierarchic.LayeringStrategy.HIERARCHICAL_TOPMOST;
    newLayout.nodePlacer.barycenterMode = true;
    newLayout.nodeToNodeDistance = 25;
    newLayout.nodeToEdgeDistance = 25;
    newLayout.edgeToEdgeDistance = 25;
    newLayout.minimumLayerDistance = 50;
    newLayout.edgeLayoutDescriptor.minimumDistance = 25;

    return newLayout;
  }

  getOrthogonalLayout() {
    let newLayout = new OrthogonalLayout();
    newLayout.uniformPortAssignment = true;
    newLayout.minimumNodeDistance = 80;
    newLayout.edgeLayoutDescriptor.minimumDistance = 25;

    return newLayout;
  }

  getCircularLayout() {
    let newLayout = new CircularLayout();

    return newLayout;
  }

  getRadialLayout() {
    let newLayout = new RadialLayout();

    return newLayout;
  }

  getInteractiveOrganicLayout() {
    const organicLayout = new InteractiveOrganicLayout({
      maximumDuration: MAX_TIME,
      // The compactness property prevents component drifting.
      compactnessFactor: 0.4
    });

    organicLayout.preferredEdgeLength = 80;
    organicLayout.compactnessFactor = 0.2;
    organicLayout.preferredNodeDistance = 80;

    layoutContext = organicLayout.startLayout(copiedLayoutGraph);

    // use an animator that animates an infinite animation
    animator = new Animator(this.graphComponent);
    animator.autoInvalidation = false;
    animator.allowUserInteraction = true;

    /*animator.animate(() => {
      layoutContext.continueLayout(20);
      if (organicLayout.commitPositionsSmoothly(50, 0.05) > 0) {
        this.graphComponent.updateVisual();
      }
    }, Number.POSITIVE_INFINITY);*/

    return organicLayout;
  }

  async changeLayout(type) {
    
    this.initInputMode(type);

    switch (type) {
      case "hierarchic":
        layout = this.getHierarchicLayout();
        break;
      case "organic":
        layout = this.getOrganicLayout();
        break;
      case "interactive-organic":
        {
          if(layout !== null && !(layout instanceof OrganicLayout)){
            console.log('ok');
            layout = this.getOrganicLayout();
            await this.graphComponent.morphLayout(layout);
          }
          copiedLayoutGraph = new LayoutGraphAdapter(this.graphComponent.graph).createCopiedLayoutGraph();
          layout = this.getInteractiveOrganicLayout();
          this.wakeUp(this, EventArgs.EMPTY)
          break;
        }
      case "circular":
        layout = this.getCircularLayout();
        break;
      case "radial":
        layout = this.getRadialLayout();
        break;
      case "orthogonal":
        layout = this.getOrthogonalLayout();
        break;
      default:
        layout = this.getOrganicLayout();
        break;
    }


    if (type !== "interactive-organic") {
      if(animator!==null){
        animator.stop();
        animator = null;
      }
   
      this.layouting = true;
      await this.graphComponent.morphLayout(layout);
      this.layouting = false;
    }

    const nodeSelection = this.graphComponent.selection.selectedNodes
    this.graphComponent.graph.nodes.forEach(node => {
      nodeSelection.setSelected(node, false)
    })
    
    this.graphComponent.currentItem = null;

    const inputMode = this.graphComponent.inputMode;

    //The pop-up is shown for the currentItem thus nodes and edges should be focusable
    inputMode.focusableItems = GraphItemTypes.NODE | GraphItemTypes.EDGE;

    // On clicks on empty space, set currentItem to <code>null</code> to hide the pop-ups
    inputMode.addCanvasClickedListener((sender, args) => {
      graphComponent.currentItem = null;
      $(".toolbar .toolbar-panel").hide();
      $(".toolbar-menu-item").removeClass("active");
    });

    // On press of the ESCAPE key, set currentItem to <code>null</code> to hide the pop-ups
    const hidePopupCommand = ICommand.createCommand("Hide Popup");
    inputMode.keyboardInputMode.addKeyBinding(
      Key.ESCAPE,
      ModifierKeys.NONE,
      hidePopupCommand
    );

    inputMode.keyboardInputMode.addCommandBinding(
      hidePopupCommand,
      (command, parameter, source) => {
        source.currentItem = null;
      }
    );

    this.graphComponent.fitGraphBounds();
  }

  initInputMode(type) {

    if (type === "interactive-organic") {
      graphViewerInputMode = new GraphEditorInputMode({
        selectableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
        deletableItems: GraphItemTypes.NONE,
        marqueeSelectableItems: GraphItemTypes.NODE,
        clickSelectableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
        clickableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
        showHandleItems: GraphItemTypes.NONE,
        allowAddLabel: false,
        allowCreateNode: false,
        allowCreateEdge: false,
        allowDuplicate: false,
        allowClipboardOperations: false,
        allowEditLabel: false
      });

      graphViewerInputMode.createBendInputMode.enabled = false;
      graphViewerInputMode.createEdgeInputMode.allowCreateBend = false;
      graphViewerInputMode.createEdgeInputMode.allowSelfloops = false;

      // prepare the move input mode for interacting with the layout algorithm
      this.initMoveMode(graphViewerInputMode.moveInputMode);

      // We could also allow direct moving of nodes, without requiring selection of the nodes, first.
      // However by default this conflicts with the edge creation gesture, which we will simply disable, here.
      // uncomment the following lines to be able to move nodes without selecting them first
      //
      graphViewerInputMode.moveUnselectedInputMode.enabled = true;
      this.initMoveMode(graphViewerInputMode.moveUnselectedInputMode);
    } else {
      // graphViewerInputMode = new GraphViewerInputMode({
      //   toolTipItems: GraphItemTypes.LABEL_OWNER,
      //   clickableItems: GraphItemTypes.NODE,
      //   focusableItems: GraphItemTypes.NODE,
      //   selectableItems: GraphItemTypes.NONE,
      //   marqueeSelectableItems: GraphItemTypes.NONE,
      //   itemHoverInputMode: {
      //     hoverItems: GraphItemTypes.EDGE
      //   }
      // });

      // graphViewerInputMode = new GraphViewerInputMode({
      //   toolTipItems: GraphItemTypes.LABEL_OWNER,
      //   clickableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
      //   focusableItems: GraphItemTypes.NODE,
      //   selectableItems: GraphItemTypes.NONE,
      //   marqueeSelectableItems: GraphItemTypes.NONE
      // });

      graphViewerInputMode = new GraphEditorInputMode({
        toolTipItems: GraphItemTypes.LABEL_OWNER,
        selectableItems: GraphItemTypes.NODE,
        //selectableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
        deletableItems: GraphItemTypes.NONE,
        marqueeSelectableItems: GraphItemTypes.NODE,
        clickSelectableItems: GraphItemTypes.NODE,
        //clickSelectableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
        clickableItems: GraphItemTypes.NODE | GraphItemTypes.EDGE,
        movableItems: GraphItemTypes.NODE,
        showHandleItems: GraphItemTypes.NONE,
        allowAddLabel: false,
        allowCreateNode: false,
        allowCreateEdge: false,
        allowDuplicate: false,
        allowClipboardOperations: false,
        allowEditLabel: false
      });


      graphViewerInputMode.moveInputMode.enabled = true;

      graphViewerInputMode.moveUnselectedInputMode.enabled = true;

      



      //this.initMoveMode(graphViewerInputMode.moveUnselectedInputMode);



      // Disable the standard move input mode
      //graphEditorInputMode.moveInputMode.enabled = false;

      // Enable the move input mode for unselected items
      //const moveUnselectedInputMode = graphEditorInputMode.moveUnselectedInputMode;
      //moveUnselectedInputMode.enabled = true;
    }

    graphViewerInputMode.itemHoverInputMode.addHoveredItemChangedListener(
      (sender, event) => {
        if (event.oldItem) {
          if (event.oldItem instanceof IEdge) {
            event.oldItem.labels.forEach(label => {
              var hoverLabelStyle = new DefaultLabelStyle();
              this.graphComponent.graph.setStyle(label, hoverLabelStyle);
            });
          }
          this.graphComponent.highlightIndicatorManager.removeHighlight(
            event.oldItem
          );
        }
        if (event.item) {
          if (event.item instanceof IEdge) {
            event.item.labels.forEach(label => {
              var hoverLabelStyle = new DefaultLabelStyle();
              hoverLabelStyle.textSize = 16;
              hoverLabelStyle.textFill = "#2196F3";
              this.graphComponent.graph.setStyle(label, hoverLabelStyle);
            });
          }
          this.graphComponent.highlightIndicatorManager.addHighlight(
            event.item
          );
        }
      }
    );

    graphViewerInputMode.addItemDoubleClickedListener(() => {
      const currentItem = this.graphComponent.currentItem;

      if (INode.isInstance(currentItem)) {
        if (this.graphComponent.graph.contains(currentItem)) {
          ICommand.ZOOM_TO_CURRENT_ITEM.execute(null, this.graphComponent);
        }
      }
    });

    graphViewerInputMode.moveUnselectedInputMode.addDragStartedListener(
      (sender, event) => {
      movedNodes = sender.affectedItems
      .filter(item => INode.isInstance(item))
      .toArray();
      movedNodes.forEach(node => {
        if (this.graphComponent.graph.contains(node)) {
          const edgesInOut = this.graphComponent.graph.edgesAt(node);
          edgesInOut.forEach(edge => {
            this.graphComponent.graph.clearBends(edge);
          })
        }
      })
    });

    graphViewerInputMode.moveInputMode.addDragStartedListener(
      (sender, event) => {
      movedNodes = sender.affectedItems
      .filter(item => INode.isInstance(item))
      .toArray();
      movedNodes.forEach(node => {
        if (this.graphComponent.graph.contains(node)) {
          const edgesInOut = this.graphComponent.graph.edgesAt(node);
          edgesInOut.forEach(edge => {
            this.graphComponent.graph.clearBends(edge);
          })
        }
      })
    });

    graphViewerInputMode.addCanvasClickedListener((sender, args) => {
      this.graphComponent.currentItem = null;
    });

    this.graphComponent.maximumZoom = ZOOM_MAX_VALUE;

    this.graphComponent.inputMode = graphViewerInputMode;
  }


  initMoveMode(moveInputMode) {
    // register callbacks to notify the organic layout of changes
    moveInputMode.addDragStartedListener(this.onMoveInitialized.bind(this));
    moveInputMode.addDragCanceledListener(this.onMoveCanceled.bind(this));
    moveInputMode.addDraggedListener(this.onMoving.bind(this));
    moveInputMode.addDragFinishedListener(this.onMovedFinished.bind(this));
  }

  // unsubscribeMoveMode(moveInputMode){
  //   moveInputMode.removeDragStartedListener(this.onMoveInitialized.bind(this));
  //   moveInputMode.removeDragCanceledListener(this.onMoveCanceled.bind(this));
  //   moveInputMode.removeDraggedListener(this.onMoving.bind(this));
  //   moveInputMode.removeDragFinishedListener(this.onMovedFinished.bind(this));
  // }

  setMouseEventRecognizer(mouseEventRecognizer) {
    graphViewerInputMode.moveViewportInputMode.pressedRecognizer = mouseEventRecognizer;
  }

  onMoveInitialized(sender, args) {
    if (layout !== null) {
      this.graphComponent.currentItem = null;
      const copy = copiedLayoutGraph;
      const componentNumber = copy.createNodeMap();
      GraphConnectivity.connectedComponents(copy, componentNumber);
      const movedComponents = new Set();
      const selectedNodes = new Set();
      movedNodes = sender.affectedItems
        .filter(item => INode.isInstance(item))
        .toArray();
      movedNodes.forEach(node => {
        const copiedNode = copy.getCopiedNode(node);
        if (copiedNode !== null) {
          // remember that we nailed down this node
          selectedNodes.add(copiedNode);
          // remember that we are moving this component
          movedComponents.add(componentNumber.getInt(copiedNode));
          // Update the position of the node in the CLG to match the one in the IGraph
          layout.setCenter(
            copiedNode,
            node.layout.x + node.layout.width * 0.5,
            node.layout.y + node.layout.height * 0.5
          );
          // Actually, the node itself is fixed at the start of a drag gesture
          layout.setInertia(copiedNode, 1.0);
          // Increasing has the effect that the layout will consider this node as not completely placed...
          // In this case, the node itself is fixed, but it's neighbors will wake up
          this.increaseHeat(copiedNode, layout, 0.5);
        }
      }, this);

      // make all nodes that are not actively moved float freely
      copy.nodes.forEach(copiedNode => {
        if (!selectedNodes.has(copiedNode)) {
          layout.setInertia(copiedNode, 0);
        }
      });

      // dispose the map
      copy.disposeNodeMap(componentNumber);

      // Notify the layout that there is new work to do...
      layout.wakeUp();
    }
  }

  /**
   * Notifies the layout of the new positions of the interactively moved nodes.
   * @param {object} sender
   * @param {InputModeEventArgs} args
   */
  onMoving(sender, args) {
    if (layout !== null) {
      const copy = copiedLayoutGraph;
      movedNodes.forEach(node => {
        const copiedNode = copy.getCopiedNode(node);
        if (copiedNode !== null) {
          // Update the position of the node in the CLG to match the one in the IGraph
          layout.setCenter(
            copiedNode,
            node.layout.center.x,
            node.layout.center.y
          );
          // Increasing the heat has the effect that the layout will consider these nodes as not completely placed...
          this.increaseHeat(copiedNode, layout, 0.05);

        }
      }, this);
      // Notify the layout that there is new work to do...
      layout.wakeUp();
    }
  }

  /**
   * Resets the state in the layout when the user cancels the move operation.
   * @param {object} sender
   * @param {InputModeEventArgs} args
   */
  onMoveCanceled(sender, args) {
    if (layout !== null) {
      const copy = copiedLayoutGraph;
      movedNodes.forEach(node => {
        const copiedNode = copy.getCopiedNode(node);
        if (copiedNode !== null) {
          // Update the position of the node in the CLG to match the one in the IGraph
          layout.setCenter(
            copiedNode,
            node.layout.center.x,
            node.layout.center.y
          );
          layout.setStress(copiedNode, 0);
        }
      });
      copy.nodes.forEach(copiedNode => {
        // Reset the node's inertia to be fixed
        layout.setInertia(copiedNode, 1.0);
        layout.setStress(copiedNode, 0);
      }); // We don't want to restart the layout (since we canceled the drag anyway...)
    }
  }

  /**
   * Called once the interactive move is finished.
   * Updates the state of the interactive layout.
   * @param {object} sender
   * @param {InputModeEventArgs} args
   */
  onMovedFinished(sender, args) {
    if (layout !== null) {
      const copy = copiedLayoutGraph;
      movedNodes.forEach(node => {
        const copiedNode = copy.getCopiedNode(node);
        if (copiedNode !== null) {
          // Update the position of the node in the CLG to match the one in the IGraph
          layout.setCenter(
            copiedNode,
            node.layout.center.x,
            node.layout.center.y
          );
          layout.setStress(copiedNode, 0);
        }
      });
      copy.nodes.forEach(copiedNode => {
        // Reset the node's inertia to be fixed
        layout.setInertia(copiedNode, 1.0);
        layout.setStress(copiedNode, 0);
      });
    }
  }

  /**
   * Wakes up the layout algorithm.
   * @param {object} sender
   * @param {EventArgs} args
   */
  wakeUp(sender, args) {
    if (layout !== null) {
      // we make all nodes freely movable
      copiedLayoutGraph.nodes.forEach(copiedNode => {
        layout.setInertia(copiedNode, 0);
      });
      // then wake up the layout
      layout.wakeUp();

      const geim = this.graphComponent.inputMode;
      // and after two seconds we freeze the nodes again...
      window.setTimeout(() => {
        if (geim.moveInputMode.isDragging) {
          // don't freeze the nodes if a node is currently being moved
          return;
        }
        copiedLayoutGraph.nodes.forEach(copiedNode => {
          layout.setInertia(copiedNode, 1);
        });
      }, 2000);
    }
  }

  /**
   * Synchronizes the structure of the graph copy with the original graph.
   */
  synchronize() {
    if (layout !== null) {
      layout.syncStructure();
      layoutContext.continueLayout(10);
    }
  }

  /**
   * Helper method that increases the heat of the neighbors of a given node by a given value.
   * This will make the layout algorithm move the neighbor nodes more quickly.
   * @param {YNode} copiedNode
   * @param {InteractiveOrganicLayout} layoutAlgorithm
   * @param {number} delta
   */
  increaseHeat(copiedNode, layoutAlgorithm, delta) {
    // Increase Heat of neighbors
    copiedNode.neighbors.forEach(neighbor => {
      const oldStress = layoutAlgorithm.getStress(neighbor);
      layoutAlgorithm.setStress(neighbor, Math.min(1, oldStress + delta));
    });
  }
}

export default LayoutHandler;
