//  import "jspdf-yworks/dist/jspdf.debug.js";
//  import "svg2pdf.js/dist/svg2pdf.js";
import "./BisnodeSans-Bold-normal.js";
import "./BisnodeSans-Regular-normal.js";
import "./FontAwesome-normal.js";
// import "jspdf-customfonts/dist/default_vfs.js";

import "noty/src/noty.scss";
import "noty/src/themes/nest.scss";

import "yfiles/yfiles.css";
import "../style.scss";

import tippy from "tippy.js";
import "isomorphic-fetch";
import Noty from "noty";
import $ from "jquery";
import moment from "moment";
import LayoutHandler from "./layout-handler";
import CompanyNodeStyle from "./node-style";
import MySimpleEdgeStyle from "./edge-style";
import { FastGraphModelManager } from "./fast-graph-model-manager";
import { OptimizationMode } from "./fast-graph-model-manager";
import SimpleSvgNodeStyle from "./simple-svg-node-style";
import SvgEdgeStyle from "./svg-edge-style";
import HTMLPopupSupport from "./html-popup-support";
import Toolbar from "./toolbar";
import User from "./user";
import DataService from "./data-service";
import EventEmitter from "./event-emitter";
import LicenseProvider from "./license-provider"

import {
  License,
  ICommand,
  GraphComponent,
  Class,
  Color,
  LayoutExecutor,
  INode,
  IEdge,
  Size,
  Key,
  ModifierKeys,
  InteriorLabelModel,
  SmartEdgeLabelModel,
  GraphOverviewComponent,
  GraphItemTypes,
  GraphBuilder,
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  EdgePathLabelModel,
  EventRecognizers,
  MouseEventRecognizers,
  NodeStyleDecorationInstaller,
  ShapeNodeStyle
} from "yfiles";

var LicenseProviderObj = new LicenseProvider();
License.value = LicenseProviderObj.ProvideLicense();

let toolbar = null;
let graphComponent = null;
let graphBuilder = null;
let graph = null;
let goc = null;
let layoutHandler = null;
let graphModelManager = null;
let nodePopup = null;
let edgePopup = null;
let descriptorMapper = null;

let currentEntityId = null;
let sessionId = null;
let userId = null;
let isPartnerManager = null;
let dataService = null;
let emitter = null;
let user = null;
const testGroupId = 1720735;
let lastInputDevice = null; 
let nodeDecorationInstaller = null;
let ctrlPressed = false;
let newGraphLayoutTypeWarningForToday = null;
let newGraphLayoutTypeWarningNumber = null;


// We need to load the yfiles/view-layout-bridge module explicitly to prevent the webpack
// tree shaker from removing this dependency which is needed for 'morphLayout' in this demo.
Class.ensure(LayoutExecutor);
/**
 * A simple yFiles application that creates a GraphComponent and enables basic input gestures.
 */
class Graphapp {
  async initialize() {

   

    newGraphLayoutTypeWarningForToday = this.getCookie("newGraphLayoutTypeWarningForToday");

    var d = new Date();
    var y = d.getFullYear();
    var m = d.getMonth() + 1; //months from 1-12
    var d = d.getDate();
    var ymd = y + "/" + m + "/" + d;

    if (newGraphLayoutTypeWarningForToday == null || newGraphLayoutTypeWarningForToday != ymd)
    {
      this.setCookie("newGraphLayoutTypeWarningForToday", ymd, 1);
    }

    newGraphLayoutTypeWarningNumber = this.getCookie("newGraphLayoutTypeWarningNumber");

    if (newGraphLayoutTypeWarningNumber == null)
    {
      newGraphLayoutTypeWarningNumber = 1;
    }
    else
    {
      if (newGraphLayoutTypeWarningForToday == null)
      {
        newGraphLayoutTypeWarningNumber = parseInt(newGraphLayoutTypeWarningNumber) + 1;
      }

    }

    this.setCookie("newGraphLayoutTypeWarningNumber", newGraphLayoutTypeWarningNumber.toString(), 365);


    emitter = new EventEmitter();

    $(".main-container").show();
    // new Noty({
    //   theme: 'nest',
    //   text: "Some notification text"
    // }).show();

    currentEntityId = this.urlParam("groupId");
    sessionId = this.urlParam("sessionId");
    userId = +this.urlParam("userId");
    isPartnerManager = this.urlParam("isPartnerManager");



    let apiUrl = "https://companydata.partnercontrol.hu";
    if (document.referrer.split('/')[2] == 'new.partnercontrol.hu')
    {
      apiUrl = "https://test.partnercontrol.hu";
    }


    await fetch(
      `${apiUrl}/api/graph/user-info/${userId}/${sessionId}/${isPartnerManager}`
    )
      .then(response => {
        return response.json();
      }).then(jsonResponse=>{
         user = new User(jsonResponse.userId, sessionId, jsonResponse.isPro, currentEntityId === testGroupId ? true : false);
      });

    //fakeUser
    // let user = new User(userId, sessionId, true, false);

    dataService = new DataService(user, currentEntityId, emitter);

    tippy(".tippy", {
      placement: "bottom"
    });
    // create a GraphComponent
    graphComponent = new GraphComponent("#graphComponent");
    toolbar = new Toolbar(graphComponent, currentEntityId, dataService, user);

    //hack
    toolbar.setLayoutButtons(false);
    // get the IGraph
    graph = graphComponent.graph;
    graph.nodeDefaults.size = new Size(340, 100);
    layoutHandler = new LayoutHandler(graphComponent);
    goc = new GraphOverviewComponent("#graphOverviewComponent", graphComponent);
    // create and configure a default node style
    graph.nodeDefaults.style = new CompanyNodeStyle(currentEntityId);
    graph.nodeDefaults.shareStyleInstance = true;
    graph.edgeDefaults.style = new MySimpleEdgeStyle();
    graph.edgeDefaults.shareStyleInstance = true;

    graph.nodeDefaults.labels.layoutParameter = InteriorLabelModel.CENTER;

    // Set the label model for edges
    graph.edgeDefaults.labels.layoutParameter = new SmartEdgeLabelModel({
      autoRotation: true
    }).createParameterFromSource(0, 5.0, 0);

    this.initGraphModelManager();
    this.registerListeners();
    this.createGraphBuilder();
    layoutHandler.initInputMode('organic');
    await this.populateGraph();
    // await layoutHandler.changeLayout('organic');
    this.initializeDecoration();
    this.initializePopups();

    
    // create an input mode
    // graphComponent.inputMode = new GraphEditorInputMode();
  }

  constructor() {
    this.initialize();
  }

  async populateGraph() {
    Noty.closeAll();
    new Noty({
      text: "Gráf generálása folyamatban.",
      theme: "nest",
      type: "info"
    }).show();
    await dataService
      .fetchGraphData()
      .then(async () => {
        graphBuilder.nodesSource = dataService.getNodeSource();
        graphBuilder.edgesSource = dataService.getEdgeSource();
        graphBuilder.sourceNodeBinding = "from";
        graphBuilder.targetNodeBinding = "to";
        graphBuilder.nodeIdBinding = "id";
        graphBuilder.edgeLabelBinding = "ownerPercent";

        graphBuilder.buildGraph();

        var origLayout = document.querySelector('input[name="layout-algorithm"]:checked').value;

        //await layoutHandler.changeLayout('organic');
        await layoutHandler.changeLayout(origLayout);

        toolbar.generateToolbarTable("isCompany", "desc");

        Noty.closeAll();

        new Noty({
          text: "Gráf generálása sikeresen befejeződött.",
          theme: "nest",
          type: "success",
          timeout: 2000
        }).show();
      })
      .catch(error => {
        Noty.closeAll();
        //in case of startup and big graph
        if(graphBuilder.nodesSource.length > 0){
          console.log(error);
          new Noty({
            type: "error",
            text: error,
            theme: "nest",
            timeout: 5000
          }).show();
        }
      
      });

      //Noty.closeAll();

      //ha ma még nem szólt, csak akkor szóljon

      var d = new Date();
      var y = d.getFullYear();
      var m = d.getMonth() + 1; //months from 1-12
      var d = d.getDate();
      var ymd = y + "/" + m + "/" + d;

      if (newGraphLayoutTypeWarningForToday == null || newGraphLayoutTypeWarningForToday != ymd)
      {
        //ha max 3-szor szólt
        if (newGraphLayoutTypeWarningNumber < 4)
        { 
          setTimeout(function() 
          { 
            new Noty({
            text: "Újdonság: mozgatható funkcióval bővült a gráf, részletek az Információ (i) menüpontban.",
            theme: "nest",
            type: "error",
            timeout: 10000
          }).show();
          }, 3000);
        }
      }

  }

  createGraphBuilder() {
    graphBuilder = new GraphBuilder(graph);
    graphComponent.graph = graphBuilder.graph;
  }

  updateViewPort() {
    graphComponent.fitGraphBounds();
  }

 
  initGraphModelManager() {
    const edgeColor = new Color(50, 158, 152);
    const edgeThickness = 5;

    graphModelManager = new FastGraphModelManager(
      graphComponent,
      graphComponent.contentGroup
    );

    graphModelManager.intermediateZoomThreshold = 0.35;
    graphModelManager.overviewZoomThreshold = 0.05;
    graphModelManager.intermediateNodeStyle = new SimpleSvgNodeStyle(
      new Color(213, 56, 104),
      currentEntityId
    );
    graphModelManager.intermediateEdgeStyle = new SvgEdgeStyle(
      edgeColor,
      edgeThickness
    );
    graphModelManager.overviewNodeStyle = new SimpleSvgNodeStyle(
      new Color(213, 56, 104),
      currentEntityId
    );
    graphModelManager.overviewEdgeStyle = new SvgEdgeStyle(
      edgeColor,
      edgeThickness
    );
    //graphModelManager.graphOptimizationMode = OptimizationMode.LEVEL_OF_DETAIL;
    graphModelManager.graphOptimizationMode = OptimizationMode.DEFAULT;
    graphComponent.graphModelManager = graphModelManager;
    graphComponent.invalidate();
  }

  updateSelection(graphComponent, node) {
    if (node === null) {
      // clear the whole selection
      graphComponent.selection.clear();
    } else if (!graphComponent.selection.selectedNodes.isSelected(node)) {
      // no - clear the remaining selection
      graphComponent.selection.clear();
      // and select the node
      graphComponent.selection.selectedNodes.setSelected(node, true);
      // also update the current item
      graphComponent.currentItem = node;
    }
  }

  zoomToCurrentItem() {
    const currentItem = graphComponent.currentItem;

    if (INode.isInstance(currentItem)) {
      if (graph.contains(currentItem)) {
        ICommand.ZOOM_TO_CURRENT_ITEM.execute(null, graphComponent);
      }
    }
  }

  updateViewport() {
    graphComponent.fitGraphBounds();
  }

  registerListeners() {

    lastInputDevice = 'mouse';

    graphComponent.div.addEventListener(
      'touchstart',
      evt => {
        if (lastInputDevice !== 'touch') {
          lastInputDevice = 'touch';
          layoutHandler.setMouseEventRecognizer(EventRecognizers.NEVER);
          // this.$_configureInteraction(graphComponent.inputMode, true)
        }
      },
      false
    );

    graphComponent.div.addEventListener(
      'mousedown',
      evt => {
        if (lastInputDevice !== 'mouse') {
          lastInputDevice = 'mouse';
          layoutHandler.setMouseEventRecognizer(MouseEventRecognizers.LEFT_DOWN);
          // this.$_configureInteraction(graphComponent.inputMode, false)
        }
      },
      false
    );


    emitter.subscribe("event:big-graph", async () => {
      var currentStep = document.querySelector(
        'input[name="step-count"]:checked'
      ).value;
      if (currentStep > 1) {
        document.querySelectorAll('input[name="step-count"]')[
          currentStep - 2
        ].checked = true;
        await this.populateGraph();
        this.initializeDecoration();
        this.initializePopups();
      }
    });

    let zoomLevelChangedTimer = -1;
    graphComponent.addZoomChangedListener(() => {
      // update the zom level display every 200ms
      if (zoomLevelChangedTimer >= 0) {
        return;
      }
      zoomLevelChangedTimer = setTimeout(() => {
        $("#zoomLevel").html(
          Math.floor(graphComponent.zoom * 100).toString() + "%"
        );
        zoomLevelChangedTimer = -1;
      }, 200);
    });

    graphComponent.addCurrentItemChangedListener(() =>
      this.onCurrentItemChanged()
    );

    graphComponent.addMouseDownListener((sender, args) => {
      //Ha a Ctrl-t lenyomva tartja, amikor klikkel
      ctrlPressed = false;
      if (args.modifiers == ModifierKeys.CONTROL) {
        ctrlPressed = true;
        nodePopup.currentItem = null;
        edgePopup.currentItem = null;
      }
    });

    graphComponent.addMouseDragListener((sender, args) => {
      //ha húzza az egérrel
      nodePopup.currentItem = null;
      edgePopup.currentItem = null;
    });

    graphComponent.addTouchMoveListener((sender, args) => {
      //ha húzza az ujját a képernyőn
      nodePopup.currentItem = null;
      edgePopup.currentItem = null;
    });

    $("#filter-form").submit(async e => {
      e.preventDefault();
      await this.populateGraph();
      this.initializeDecoration();
      this.initializePopups();
    });
  }

  onCurrentItemChanged() {
    const currentItem = graphComponent.currentItem;

    if (INode.isInstance(currentItem) && !ctrlPressed) {
      this.updateNodePopupContent(nodePopup, currentItem);
      nodePopup.currentItem = currentItem;
    } else {
      nodePopup.currentItem = null;
    }
    
    if (IEdge.isInstance(currentItem) ) {
      this.updateEdgePopupContent(edgePopup, currentItem);
      edgePopup.currentItem = currentItem;
    } else {
      edgePopup.currentItem = null;
    }


  }


  initializeDecoration() {
    nodeDecorationInstaller = new NodeStyleDecorationInstaller({
      nodeStyle: new ShapeNodeStyle({
        shape: 'rectangle',
        stroke: '10px rgb(213, 56, 104)',
        fill: 'transparent'
      }),
      margins: 4
    })
   
    const decorator = graphComponent.graph.decorator
  
    const nodeSelection = decorator.nodeDecorator.selectionDecorator
    nodeSelection.setImplementation(node => true, nodeDecorationInstaller)
  
  }




  initializePopups() {
    // Creates a label model parameter that is used to position the node pop-up
    const nodeLabelModel = new ExteriorLabelModel({
      insets: 10
    });

    // Creates the pop-up for the node pop-up template
    nodePopup = new HTMLPopupSupport(
      graphComponent,
      window.document.getElementById("nodePopupContent"),
      nodeLabelModel.createParameter(ExteriorLabelModelPosition.NORTH)
    );


    const edgeLabelModel = new EdgePathLabelModel({ autoRotation: false })

    edgePopup = new HTMLPopupSupport(
      graphComponent,
      window.document.getElementById("edgePopupContent"),
      edgeLabelModel.createDefaultParameter()
    );



    // The following works with both GraphEditorInputMode and GraphViewerInputMode
    const inputMode = graphComponent.inputMode;

    //The pop-up is shown for the currentItem thus nodes and edges should be focusable
    inputMode.focusableItems = GraphItemTypes.NODE | GraphItemTypes.EDGE;

    // On clicks on empty space, set currentItem to <code>null</code> to hide the pop-ups
    inputMode.addCanvasClickedListener((sender, args) => {
      graphComponent.currentItem = null;
      $(".toolbar .toolbar-panel").hide();
      $(".toolbar-menu-item").removeClass("active");

    });

    // On press of the ESCAPE key, set currentItem to <code>null</code> to hide the pop-ups
    const hidePopupCommand = ICommand.createCommand("Hide Popup");
    inputMode.keyboardInputMode.addKeyBinding(
      Key.ESCAPE,
      ModifierKeys.NONE,
      hidePopupCommand
    );

    inputMode.keyboardInputMode.addCommandBinding(
      hidePopupCommand,
      (command, parameter, source) => {
        source.currentItem = null;
      }
    );

    document.getElementById("popup-close").addEventListener("click", e => {
      e.preventDefault();
      graphComponent.currentItem = null;
    });

    document.getElementById("edge-popup-close").addEventListener("click", e => {
      e.preventDefault();
      graphComponent.currentItem = null;
    });


  }


  updateNodePopupContent(nodePopup, node) {
    const data = node.tag;

    if (data.regNumber !== null) {
        document.getElementById("regnumber-group").style.display = "block";
    } else {
        document.getElementById("regnumber-group").style.display = "none";
    }
    if (data.taxNumber !== null) {
        document.getElementById("taxnumber-group").style.display = "block";
    } else {
        document.getElementById("taxnumber-group").style.display = "none";
    }
    if (data.sales !== null) {
        document.getElementById("sales-group").style.display = "block";
    } else {
        document.getElementById("sales-group").style.display = "none";
    }
    if (data.employee !== null) {
        document.getElementById("count-group").style.display = "block";
    } else {
        document.getElementById("count-group").style.display = "none";
    }

    document.getElementById("popup-name").textContent = data.name;
    document.getElementById("popup-address").textContent =
        data.address !== null ? data.address : "-";
    if(data.privateEntrepreneurRegNumber > 0 && data.regNumber.substr(0,2) == "00")
    {
      document.getElementById("popup-regnumber-descr").textContent = "Egyéni vállalkozó nyilvántartási száma";
      document.getElementById("popup-regnumber").textContent =
        data.privateEntrepreneurRegNumber;
    }
    else
    {
      document.getElementById("popup-regnumber-descr").textContent = "Cégjegyzékszám";
      document.getElementById("popup-regnumber").textContent =
        data.regNumber !== null ? data.regNumber : "-";
    }
    document.getElementById("popup-taxnumber").textContent =
        data.taxNumber !== null ? data.taxNumber : "-";
    document.getElementById("popup-sales").textContent =
        data.sales !== null
            ? this.numberWithCommas(data.sales.amount) +
            " " +
            this.numberWithCommas(data.sales.currency) +
            " (" +
            data.sales.year +
            ")"
            : "-";
    document.getElementById("popup-count").textContent =
        data.employee !== null
            ? data.employee.count + " fő " + " (" + data.employee.year + ")"
            : "-";

    var urlButton = $(
        "#nodePopupContent .content .popup-action-button-container #popup-company-report"
    );

    let url = isPartnerManager === "true" ? "https://www.partnermanager.hu/" : "https://www.partnercontrol.hu/";

    if (document.referrer.split('/')[2] == 'devteszt.partnercontrol.hu')
    {
      url = "http://devteszt.partnercontrol.hu/";
    }


    if (urlButton.length) {
        if (data.isCompany === true && data.url) {
            $(urlButton).attr("href", url + "report/" + data.url);
        } else {
            $(urlButton).remove();
        }
    } else {
        if (data.url) {
            $("#nodePopupContent .content .popup-action-button-container").prepend(
                '<a id="popup-company-report" class="btn btn--popup" href="' + url + "report/" +
                data.url +
                '" target="_blank">Cégadatlap</a>'
            );
        }
    }

    var interestsButton = $("#popup-interests");
    if (data.url == null)
    {
      if (data.id < 0)
      {
        $(interestsButton).attr(
            "href",
            url + "kapcsolati_halo_frame.asp?group_id=" +
            data.id
        );
      }
      else
      {
        $(interestsButton).attr(
          "href",
          url + "graph/" +
          data.id
        );
      }
    }
    else
    {
      $(interestsButton).attr(
        "href",
        url + "graph/" +
        data.url
    );
    }

    $("#positive-event-list").empty();
    $("#negative-event-list").empty();
    if (data.positiveEvent) {
        data.positiveEvent.forEach(item => {
            let startDate = null;
            if (item.startDate) startDate = moment(item.startDate);
            $("#positive-event-list").append(
                '<li class="event-list__item event-list__item--positive"><div class="event-icon event-icon--green"><i class="fa fa-plus"></i></div><div class="event-content">' +
                (startDate
                    ? '<div class="date">' +
                    startDate.format("YYYY.MM.DD.") +
                    "</div>"
                    : "") +
                '<div class="descr">' +
                item.description +
                "</div></div></li>"
            );
        });
    }

    if (data.legalOtherEvent) {
        data.legalOtherEvent.forEach(item => {
            let startDate = null;
            if (item.startDate) startDate = moment(item.startDate);
            $("#negative-event-list").append(
                '<li class="event-list__item event-list__item--positive"><div class="event-icon event-icon--yellow"><i class="fa fa-exclamation"></i></div><div class="event-content">' +
                (startDate
                    ? '<div class="date">' +
                    startDate.format("YYYY.MM.DD.") +
                    "</div>"
                    : "") +
                '<div class="descr">' +
                item.description +
                "</div></div></li>"
            );
        });
    }

    if (data.legalCourtEvent) {
        data.legalCourtEvent.forEach(item => {
            let startDate = null;
            if (item.startDate) startDate = moment(item.startDate);
            $("#negative-event-list").append(
                '<li class="event-list__item event-list__item--positive"><div class="event-icon event-icon--red"><i class="fa fa-gavel"></i></div><div class="event-content">' +
                (startDate
                    ? '<div class="date">' +
                    startDate.format("YYYY.MM.DD.") +
                    "</div>"
                    : "") +
                '<div class="descr">' +
                item.description +
                "</div></div></li>"
            );
        });
    }
  }

  updateEdgePopupContent(edgePopup, edge) {
    const data = edge.tag;
    let descrText = "Inaktív";
    let valText = "";
    if (data.isActive)
    {
      descrText = "Aktív"
    }
    if (data.isOwner)
    {
      descrText += " tulajdonos";
      if (data.ownerPercent != null) {
        valText = data.ownerPercent;
      }
      // else {
      //   valText = "N/A";
      // }
    }
    else
    {
      descrText += " vezető";
    }
    document.getElementById("popup-percent-descr").textContent = descrText;
    document.getElementById("popup-percent").textContent = valText;
  }
   

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString() + ";SameSite=None; Secure";
  }

  getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }
  
  urlParam(name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }
}

new Graphapp();
